<template>
  <header class="header" :class="{'is-scrolling': scrolling}">
    <Container>
      <div class="top">
        <RouterLink :to="{name: 'home'}" title="Hawkins and sons home">
          <Logo />
        </RouterLink>

        <div class="right">
          <Contact class="contact" />

          <button
            class="nav-button"
            @click="$emit('toggleNav')"
            aria-label="Toggle navigation"
          >
            <Sprite :name="menuIcon" />
          </button>
        </div>
      </div>

      <Navigation :active="navigating" @close="$emit('toggleNav')" />
    </Container>
  </header>
</template>

<script>
import Contact from './Contact'
import Container from '@/components/Container'
import Logo from './Logo'
import Navigation from './Navigation'
import Sprite from '@/components/Sprite'

export default {
  name: 'Header',

  props: {
    navigating: {
      type: Boolean,
      required: true
    },

    scrolling: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Container,
    Contact,
    Logo,
    Navigation,
    Sprite
  },

  computed: {
    menuIcon() {
      return this.navigating ? 'close' : 'menu'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../stylesheets/base/variables"
@import "../../stylesheets/helpers/mixins"

.header
  padding: 0 $pad

  @include media($media-gt-big)
    position: fixed
    z-index: 999
    width: 100%
    background: $color-light

    ::v-deep .container
      display: flex

  ::v-deep .container
    .contained
      padding: 0

  .top
    display: flex
    justify-content: space-between
    padding: $pad * 2 0
    transition: padding $transition-duration $transition-easing

    @media print
      padding: $pad*0.5 0 !important

    @include media($media-gt-big)
      &.is-scrolling
        transition-delay: $transition-delay

      .is-scrolling &
        padding: $pad 0

    .right
      display: flex

  .logo
    width: 220px
    height: 34px

    @include media($media-gt-big)
      width: 260px
      height: 52px

    @media print
      width: 200px!important
      height: 30px!important

    @include media($media-gt-very-big)
      width: 377px
      height: 60px
      transition: width $transition-duration $transition-easing, height $transition-duration $transition-easing

      .is-scrolling &
        transition-delay: $transition-delay
        width: 260px
        height: 52px

  .nav-button
    border: 0
    background-size: cover
    width: 35px
    height: 35px
    padding: 0
    background: none
    cursor: pointer
    z-index: 999

    @media print
      display: none

    .sprite
      width: 100%
      height: 100%
      fill: $color-highlight

    @include media($media-gt-big)
      display: none
</style>
