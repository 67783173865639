import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import About from '@/views/About'
import Contact from '@/views/Contact'
import Home from '@/views/Home'
import Obituaries from '@/views/Obituaries'
import Obituary from '@/views/Obituary'

import Page from '@/views/Page'

import PostTypeIndex from '@/views/PostTypeIndex'
import PostTypeShow from '@/views/PostTypeShow'

import Catalogue from '@/views/Catalogue'
import Category from '@/views/Catalogue/Category'
import Product from '@/views/Catalogue/Product'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/advice',
    name: 'advice',
    component: PostTypeIndex,
    meta: {
      pageSlug: 'advice-index',
      postType: 'advice',
      postTypePlural: 'advice',
      postRouteName: 'advice.advice'
    }
  },

  {
    path: '/advice/:slug',
    name: 'advice.advice',
    component: PostTypeShow,
    props: true,
    meta: {
      postType: 'advice',
      postTypePlural: 'advice'
    }
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },

  {
    path: '/catalogue',
    name: 'catalogue',
    component: Catalogue
  },

  {
    path: '/catalogue/categories/:slug',
    name: 'catalogue.category',
    component: Category,
    props: true
  },

  {
    path: '/catalogue/products/:slug',
    name: 'catalogue.category.product',
    component: Product,
    props: true
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },

  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/obituaries',
    name: 'obituaries',
    component: Obituaries
  },

  {
    path: '/obituaries/:id',
    name: 'obituaries.obituary',
    component: Obituary,
    props: true
  },

  {
    path: '/packages',
    name: 'packages',
    component: PostTypeIndex,
    meta: {
      pageSlug: 'packages',
      postType: 'package',
      postTypePlural: 'packages',
      postRouteName: 'packages.package'
    }
  },

  {
    path: '/packages/:slug',
    name: 'packages.package',
    component: PostTypeShow,
    props: true,
    meta: {
      postType: 'package',
      postTypePlural: 'packages'
    }
  },

  {
    path: '/services',
    name: 'services',
    component: PostTypeIndex,
    meta: {
      pageSlug: 'services',
      postType: 'service',
      postTypePlural: 'services',
      postRouteName: 'services.service'
    }
  },

  {
    path: '/services/:slug',
    name: 'services.service',
    component: PostTypeShow,
    props: true,
    meta: {
      postType: 'service',
      postTypePlural: 'services'
    }
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: Page,
    meta: {
      wpSlug: 'privacy'
    }
  },

  {
    path: '/terms',
    name: 'terms',
    component: Page,
    meta: {
      wpSlug: 'terms'
    }
  },

  {
    path: '/standardised-pricing',
    name: 'standardised-pricing',
    component: Page,
    meta: {
      wpSlug: 'standardised-pricing'
    }
  },

  {
    path: '/disclosure-of-interest',
    name: 'disclosure-of-interest',
    component: Page,
    meta: {
      wpSlug: 'disclosure-of-interest'
    }
  }
]

function scrollBehavior() {
  return {x: 0, y: 0}
}

export function createRouter() {
  return new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
  })
}
