import { render, staticRenderFns } from "./About.vue?vue&type=template&id=dd5ef11e&scoped=true&"
import script from "./About.vue?vue&type=script&lang=js&"
export * from "./About.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./About.vue?vue&type=style&index=0&id=dd5ef11e&lang=sass&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "dd5ef11e",
  "7debe07a"
  
)

export default component.exports