<template>
  <li class="post-preview">
    <div class="column left">
      <ResponsiveImage
        v-if="featuredImageVersions"
        class="featured-image"
        :image-versions="featuredImageVersions"
        :description="title"
        :width="width"
      />

      <LazyImg
        v-if="post.acf.image"
        :url="post.acf.image"
        class="featured-image lazy-image"
      />
    </div>

    <div class="column right">
      <RouterLink class="title" :to="postRoute">
        <h3>{{ title }}</h3>
      </RouterLink>

      <p>{{ subHeading }}</p>

      <Button :underlined="buttonUnderlined" icon :to="postRoute">
        {{ buttonText }}
      </Button>
    </div>
  </li>
</template>

<script>
import post from '@/mixins/post'

import Button from '@/components/Button'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import sizable from '../mixins/sizable'
import LazyImg from '@/components/LazyImg.vue'

export default {
  name: 'PostPreview',

  mixins: [post, sizable],

  props: {
    buttonText: {
      required: false,
      default() {
        return 'Read more'
      }
    },

    post: {
      type: Object,
      required: true
    },

    postRouteName: {
      type: String,
      required: false
    },

    buttonUnderlined: {
      type: Boolean,
      default: false
    },

    route: {
      type: Function,
      required: false
    }
  },

  components: {
    Button,
    ResponsiveImage,
    LazyImg
  },

  computed: {
    postRoute() {
      if (this.route) {
        return this.route(this.post)
      }

      const name = this.postRouteName
      const params = {slug: this.post.slug}

      return {name, params}
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.post-preview
  padding: $pad

  .featured-image
    padding: $pad * 0.5

  .title
    h3
      color: $color-dark
      margin: 0

    &:hover
      text-decoration: none

  @include media($media-gt-big)
    display: flex
    align-items: center

  .column
    width: 100%
  .left
    width: 100%

    @include media($media-gt-big)
      // height: 240px

    .lazy-image
      width: 100%
      height: 100%
      max-width: 500px
      margin: auto
</style>
