<template>
  <div class="product">
    <h1>{{ title }}</h1>

    <ResponsiveImage
      :image-versions="featuredImageVersions"
      :alt="title"
      :width="width"
      class="image"
    />

    <div v-html="content"></div>
  </div>
</template>

<script>
import post from '@/mixins/post'
import sizable from '@/mixins/sizable'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Product',

  mixins: [post, sizable],

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  components: {
    ResponsiveImage
  },

  computed: {
    post() {
      return this.product
    }
  }
}
</script>

<style lang="sass" scoped>
.product
  .image
    max-width: 800px
    margin: auto
</style>
